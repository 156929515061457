import { ServerSideQuery } from '@/lib';
import {
  FbBusinessAccountByShopDocument,
  FbBusinessAccountByShopQuery,
  FbBusinessAccountByShopQueryVariables,
  FbCommentsSessionByShowDocument,
  FbCommentsSessionByShowQuery,
  FbCommentsSessionByShowQueryVariables,
  shopBySlugQuery,
  ShopShowsDocument,
  ShopShowsQuery,
  ShopShowsQueryVariables,
  ShowBySlugDocument,
  ShowBySlugQuery,
  ShowBySlugQueryVariables,
  ShowDocument,
  ShowQuery,
  ShowQueryVariables,
  toGidString
} from '@/api';

export const showBySlugQuery: ServerSideQuery = (client, context) =>
  client.query<ShowBySlugQuery, ShowBySlugQueryVariables>({
    query: ShowBySlugDocument,
    variables: {
      shopSlug: context.params?.shopSlug as string,
      showSlug: context.params?.showSlug as string
    }
  });

export const showQuery: ServerSideQuery = (client, context) =>
  client.query<ShowQuery, ShowQueryVariables>({
    query: ShowDocument,
    variables: {
      id: toGidString('Show', context.params?.showId as string)
    }
  });

export const shopShowsByShopSlugQuery: ServerSideQuery = async (client, context) => {
  const result = await shopBySlugQuery(client, context);
  if (result?.data?.shopBySlug?.id) {
    return await client.query<ShopShowsQuery, ShopShowsQueryVariables>({
      query: ShopShowsDocument,
      variables: {
        shopId: result.data.shopBySlug.id
      }
    });
  }
};

export const fbBusinessAccountsByShopSlugQuery: ServerSideQuery = async (client, context) => {
  const result = await shopBySlugQuery(client, context);
  if (result?.data?.shopBySlug?.id) {
    return await client.query<FbBusinessAccountByShopQuery, FbBusinessAccountByShopQueryVariables>({
      query: FbBusinessAccountByShopDocument,
      variables: {
        shopId: result.data.shopBySlug.id
      }
    });
  }
};

export const fbCommentsSessionByShowSlugQuery: ServerSideQuery = async (client, context) => {
  const result = await showBySlugQuery(client, context);
  if (result?.data?.showBySlug?.id) {
    return await client.query<FbCommentsSessionByShowQuery, FbCommentsSessionByShowQueryVariables>({
      query: FbCommentsSessionByShowDocument,
      variables: {
        showId: result.data.showBySlug.id
      }
    });
  }
};
