import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client';
import { typePolicies } from '@/lib/typePolicies';
import fragmentMatcher from '@/api/__generated__/possible-types';

export function createInMemoryCache() {
  return new InMemoryCache({
    dataIdFromObject(responseObject) {
      // Use GIDs as cache IDs
      if (responseObject.id) {
        return responseObject.id.toString();
      }
      return defaultDataIdFromObject(responseObject);
    },
    possibleTypes: fragmentMatcher.possibleTypes,
    typePolicies
  });
}
