import { useRelayMutation } from '@/lib';
import {
  UpdateImageDocument,
  UpdateImageMutation,
  UpdateImageMutationVariables,
  CreateImageUploadsMutation,
  CreateImageUploadsMutationVariables,
  CreateImageUploadsDocument,
  ImageUploadInput,
  uploadImage,
  CreateProfileImageUploadsMutation,
  CreateProfileImageUploadsDocument,
  CreateProfileImageUploadsMutationVariables
} from '@/api';
import { UploadImage } from '@/components/products/ProductImagesPicker';

export function useUpdateImage() {
  return useRelayMutation<UpdateImageMutation, UpdateImageMutationVariables, 'updateImage'>(
    UpdateImageDocument,
    'updateImage'
  );
}

export function useCreateImageUploads() {
  return useRelayMutation<CreateImageUploadsMutation, CreateImageUploadsMutationVariables, 'createImageUploads'>(
    CreateImageUploadsDocument,
    'createImageUploads'
  );
}

export function useCreateProfileImageUploads() {
  return useRelayMutation<
    CreateProfileImageUploadsMutation,
    CreateProfileImageUploadsMutationVariables,
    'createProfileImageUploads'
  >(CreateProfileImageUploadsDocument, 'createProfileImageUploads');
}

export type UploadProductImagesArgs = {
  shopId: string;
  images: UploadImage[];
};

export function useUploadImages() {
  const [createImageUploads] = useCreateImageUploads();

  const uploadImages = async ({ shopId, images }: UploadProductImagesArgs): Promise<UploadImage[]> => {
    const createUploadInputs = images.map(
      (uploadImage) =>
        ({
          filename: uploadImage.image.file.name,
          focusRegion: uploadImage.image.focusRegion,
          removeBackground: false
        } as ImageUploadInput)
    );

    const { imageUploads } = await createImageUploads({
      shopId: shopId,
      images: createUploadInputs
    });

    if (imageUploads) {
      for (let i = 0; i < imageUploads.length; i++) {
        images[i].imageId = imageUploads[i].imageId;
        images[i].uploadUrl = imageUploads[i].uploadUrl;
        images[i].uploading = true;
      }

      const uploadPromises = images.map(async (image: UploadImage) => uploadImage(image.uploadUrl!, image.image.file));

      await Promise.all(uploadPromises);

      return images;
    }
    return [];
  };

  return [uploadImages];
}
