import { MutationFn, MutationHookTuple, OmitMutationId } from '@/lib';
import { DocumentNode, MutationHookOptions, useMutation } from '@apollo/client';

export type UseRelayMutationHook<
  TMutation,
  TVariables extends { input?: any },
  TMutationName extends keyof TMutation
> = MutationHookTuple<OmitMutationId<TVariables['input']>, Omit<TMutation[TMutationName], '__typename'>>;

export type RelayMutationFn<
  TMutation,
  TVariables extends { input?: any },
  TMutationName extends keyof TMutation
> = MutationFn<OmitMutationId<TVariables['input']>, Omit<TMutation[TMutationName], '__typename'>>;

export function useRelayMutation<TMutation, TVariables extends { input?: any }, TMutationName extends keyof TMutation>(
  mutation: DocumentNode,
  mutationName: TMutationName,
  options?: MutationHookOptions<TMutation, TVariables>
): UseRelayMutationHook<TMutation, TVariables, TMutationName> {
  const [mutateInternal, { data, ...rest }] = useMutation<TMutation, TVariables>(mutation, options);

  const mutate: RelayMutationFn<TMutation, TVariables, TMutationName> = async (input) => {
    const { data } = await mutateInternal({
      variables: {
        input
      } as TVariables
    });
    return data?.[mutationName] ?? ({} as TMutation[TMutationName]);
  };

  return [mutate, { ...(data?.[mutationName] as TMutation[TMutationName]), ...rest }];
}
