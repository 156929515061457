export async function uploadImage(url: string, file: File) {
  const formData = new FormData();

  formData.set('file', file);

  return fetch(url, {
    method: 'POST',
    body: formData
  }).then((res) => res.json());
}

export type UploadImageParams = {
  url: string;
  file: File;
};

export async function uploadImages(images: UploadImageParams[]) {
  await Promise.all(images.map((i) => uploadImage(i.url, i.file)));
}
