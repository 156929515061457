import { getSession } from '@auth0/nextjs-auth0';
import { GetServerSidePropsContext, NextApiRequest } from 'next';

export function getAuthUrls(req: NextApiRequest) {
  const host = req.headers['host'];
  const protocol = process.env.VERCEL_URL ? 'https' : 'http';
  const redirectUri = `${protocol}://${host}/api/auth/callback`;
  const returnTo = (req.query.returnTo as string) ?? `${protocol}://${host}`;
  return {
    redirectUri,
    returnTo
  };
}

export function getFbRedirectUri(req: NextApiRequest) {
  const host = req.headers['host'];
  const protocol = process.env.VERCEL_URL ? 'https' : 'http';
  return `${protocol}://${host}/jcomments/facebook/connect-business`;
}

export async function authRedirect(ctx: GetServerSidePropsContext) {
  const session = getSession(ctx.req, ctx.res);

  if (session) {
    return {
      redirect: {
        destination: '/account',
        statusCode: 302
      }
    };
  } else {
    return {
      props: {}
    };
  }
}
