import { ServerSideQuery } from '@/lib';
import { ProductDocument, ShopOrderQuery, ShopOrderQueryVariables, toGidString } from '@/api';

export const shopOrderQuery: ServerSideQuery = async (client, context) => {
  if (context.params?.shopOrderId) {
    return await client.query<ShopOrderQuery, ShopOrderQueryVariables>({
      query: ProductDocument,
      variables: {
        id: toGidString('ShopOrder', context.params?.shopOrderId as string)
      }
    });
  }
};
