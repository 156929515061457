import { QueryHookOptions } from '@apollo/client';
import {
  useRootCategoriesQuery,
  useCategoryQuery,
  useAllShoppingCategoriesQuery,
  useShoppingSubcategoriesByIdsQuery,
  AddOrUpdateUserShoppingCategoriesMutation,
  AddOrUpdateUserShoppingCategoriesMutationVariables,
  AddOrUpdateUserShoppingCategoriesDocument,
  AddOrUpdateUserShoppingSubcategoriesMutation,
  AddOrUpdateUserShoppingSubcategoriesMutationVariables,
  AddOrUpdateUserShoppingSubcategoriesDocument
} from '@/api';
import { CategoryChildrenFragment, RootCategoriesQuery } from '@/api';
import { useRelayMutation } from '../../lib';

export function useRootCategories(options?: QueryHookOptions<RootCategoriesQuery, any>) {
  const { data, ...rest } = useRootCategoriesQuery(options);

  return { categories: (data?.categoryTree?.nodes ?? []) as CategoryChildrenFragment[], ...rest };
}

export function useAllShoppingCategories() {
  const { data, error, loading, ...rest } = useAllShoppingCategoriesQuery();

  return { shoppingCategories: data?.allShoppingCategories, loading, error, ...rest };
}

export function useShoppingSubcategoriesByIds(categoryIds: string[]) {
  const { data, error, loading, ...rest } = useShoppingSubcategoriesByIdsQuery({
    variables: {
      categoryIds: categoryIds
    }
  });

  return { shoppingSubCategories: data?.shoppingSubcategoriesByIds, loading, error, ...rest };
}

export function useCategory(id: string, skip?: boolean) {
  const { data, ...rest } = useCategoryQuery({
    variables: {
      id
    },
    skip
  });

  return {
    category: (data?.category ?? null) as CategoryChildrenFragment | null,
    ...rest
  };
}

export function useAddOrUpdateUserShoppingCategories() {
  return useRelayMutation<
    AddOrUpdateUserShoppingCategoriesMutation,
    AddOrUpdateUserShoppingCategoriesMutationVariables,
    'addOrUpdateUserShoppingCategories'
  >(AddOrUpdateUserShoppingCategoriesDocument, 'addOrUpdateUserShoppingCategories');
}

export function useAddOrUpdateUserShoppingSubcategories() {
  // return useRelayMutation<AddOrUpdateUserShoppingCategoriesMutation, AddOrUpdateUserShoppingCategoriesMutationVariables, 'addOrUpdateUserShoppingCategories'>(
  //   AddOrUpdateUserShoppingCategoriesDocument,
  //   'addOrUpdateUserShoppingCategories'
  // )
  return useRelayMutation<
    AddOrUpdateUserShoppingSubcategoriesMutation,
    AddOrUpdateUserShoppingSubcategoriesMutationVariables,
    'addOrUpdateUserShoppingSubcategories'
  >(AddOrUpdateUserShoppingSubcategoriesDocument, 'addOrUpdateUserShoppingSubcategories');
}
