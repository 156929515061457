import {
  ProductDocument,
  ProductQuery,
  ProductQueryVariables,
  shopBySlugQuery,
  ShopProductsDocument,
  ShopProductsQuery,
  ShopProductsQueryVariables,
  toGidString
} from '@/api';
import { ServerSideQuery } from '@/lib';

export const productQuery: ServerSideQuery = async (client, context) => {
  if (context.params?.productId) {
    return await client.query<ProductQuery, ProductQueryVariables>({
      query: ProductDocument,
      variables: {
        id: toGidString('Product', context.params?.productId as string)
      }
    });
  }
};

export const shopProductsByShopSlugQuery: ServerSideQuery = async (client, context) => {
  const result = await shopBySlugQuery(client, context);
  if (result?.data?.shopBySlug?.id) {
    return await client.query<ShopProductsQuery, ShopProductsQueryVariables>({
      query: ShopProductsDocument,
      variables: {
        shopId: result.data.shopBySlug.id
      }
    });
  }
};
