import { ServerSideQuery } from '@/lib';
import {
  ShopPaymentAccountsBySlugQuery,
  ShopPaymentAccountsBySlugQueryVariables,
  ShopPaymentAccountsBySlugDocument
} from '@/api';

export const shopPaymentAccountsBySlugQuery: ServerSideQuery = (client, context) =>
  client.query<ShopPaymentAccountsBySlugQuery, ShopPaymentAccountsBySlugQueryVariables>({
    query: ShopPaymentAccountsBySlugDocument,
    variables: {
      slug: context.params?.shopSlug as string
    }
  });
