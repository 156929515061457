import {
  CheckoutFragment,
  CheckoutFragmentDoc,
  CreateCheckoutFromCartDocument,
  CreateCheckoutFromCartMutation,
  CreateCheckoutFromCartMutationVariables,
  CreateCheckoutFromCartPosDocument,
  CreateCheckoutFromCartPosMutation,
  CreateCheckoutFromCartPosMutationVariables,
  SelectCheckoutShipmentShippingRateDocument,
  SelectCheckoutShipmentShippingRateMutation,
  SelectCheckoutShipmentShippingRateMutationVariables,
  UpdateCheckoutDocument,
  UpdateCheckoutPosDocument,
  UpdateCheckoutMutation,
  UpdateCheckoutMutationVariables,
  UpdateCheckoutPosMutation,
  UpdateCheckoutPosMutationVariables,
  useCheckoutQuery,
  useViewerCheckoutsByShopSlugQuery,
  ViewerCheckoutsByShopSlugDocument,
  ViewerCheckoutsByShopSlugQuery,
  ViewerCheckoutsByShopSlugQueryVariables
} from '@/api';
import { useRelayMutation } from '@/lib';
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

export function useCheckout(id: string, skip?: boolean) {
  const { data, ...rest } = useCheckoutQuery({
    variables: {
      id
    },
    skip
  });

  return {
    checkout: data?.checkout ?? null,
    checkoutLineItems: data?.checkout?.checkoutLineItems?.edges?.map((edge) => edge.node) ?? [],
    checkoutShipments: data?.checkout?.checkoutShipments?.edges?.map((edge) => edge.node) ?? [],
    ...rest
  };
}

export function useViewerCheckoutsByShopSlug(shopSlug: string, skip?: boolean) {
  const {
    data,
    refetch: refetchFn,
    ...rest
  } = useViewerCheckoutsByShopSlugQuery({
    variables: {
      shopSlug
    },
    skip
  });

  const refetch = useCallback(async () => {
    const { data } = await refetchFn();

    return {
      checkouts: data?.viewerCheckoutsByShopSlug?.edges?.map((edge) => edge.node) ?? []
    };
  }, [refetchFn]);

  return { checkouts: data?.viewerCheckoutsByShopSlug?.edges?.map((edge) => edge.node) ?? [], refetch, ...rest };
}

export function useUpdateCheckout() {
  return useRelayMutation<UpdateCheckoutMutation, UpdateCheckoutMutationVariables, 'updateCheckout'>(
    UpdateCheckoutDocument,
    'updateCheckout',
    {
      fetchPolicy: 'network-only'
    }
  );
}

export function useUpdateCheckoutPos() {
  return useRelayMutation<UpdateCheckoutPosMutation, UpdateCheckoutPosMutationVariables, 'updateCheckoutPos'>(
    UpdateCheckoutPosDocument,
    'updateCheckoutPos',
    {
      fetchPolicy: 'network-only'
    }
  );
}

export function useCreateCheckoutFromCart() {
  return useRelayMutation<
    CreateCheckoutFromCartMutation,
    CreateCheckoutFromCartMutationVariables,
    'createCheckoutFromCart'
  >(CreateCheckoutFromCartDocument, 'createCheckoutFromCart', {
    update(cache, { data }) {
      if (data?.createCheckoutFromCart?.checkout) {
        for (const cart of data?.createCheckoutFromCart?.checkout?.carts?.nodes ?? []) {
          const checkoutsQuery = cache.readQuery<
            ViewerCheckoutsByShopSlugQuery,
            ViewerCheckoutsByShopSlugQueryVariables
          >({
            query: ViewerCheckoutsByShopSlugDocument,
            variables: {
              shopSlug: cart.shop!.slug
            }
          });

          if (checkoutsQuery) {
            cache.writeQuery<ViewerCheckoutsByShopSlugQuery, ViewerCheckoutsByShopSlugQueryVariables>({
              query: ViewerCheckoutsByShopSlugDocument,
              variables: {
                shopSlug: cart.shop!.slug
              },
              data: {
                ...checkoutsQuery,
                viewerCheckoutsByShopSlug: {
                  ...checkoutsQuery.viewerCheckoutsByShopSlug!,
                  edges: [
                    { node: data.createCheckoutFromCart.checkout, cursor: '' },
                    ...(checkoutsQuery?.viewerCheckoutsByShopSlug?.edges ?? [])
                  ]
                }
              }
            });
          }
        }
      }
    }
  });
}

export function useCreateCheckoutFromCartPos() {
  return useRelayMutation<
    CreateCheckoutFromCartPosMutation,
    CreateCheckoutFromCartPosMutationVariables,
    'createCheckoutFromCartPos'
  >(CreateCheckoutFromCartPosDocument, 'createCheckoutFromCartPos', {
    update(cache, { data }) {
      if (data?.createCheckoutFromCartPos?.checkout) {
        for (const cart of data?.createCheckoutFromCartPos?.checkout?.carts?.nodes ?? []) {
          const checkoutsQuery = cache.readQuery<
            ViewerCheckoutsByShopSlugQuery,
            ViewerCheckoutsByShopSlugQueryVariables
          >({
            query: ViewerCheckoutsByShopSlugDocument,
            variables: {
              shopSlug: cart.shop!.slug
            }
          });

          if (checkoutsQuery) {
            cache.writeQuery<ViewerCheckoutsByShopSlugQuery, ViewerCheckoutsByShopSlugQueryVariables>({
              query: ViewerCheckoutsByShopSlugDocument,
              variables: {
                shopSlug: cart.shop!.slug
              },
              data: {
                ...checkoutsQuery,
                viewerCheckoutsByShopSlug: {
                  ...checkoutsQuery.viewerCheckoutsByShopSlug!,
                  edges: [
                    { node: data.createCheckoutFromCartPos.checkout, cursor: '' },
                    ...(checkoutsQuery?.viewerCheckoutsByShopSlug?.edges ?? [])
                  ]
                }
              }
            });
          }
        }
      }
    }
  });
}

export function useSelectCheckoutShipmentShippingRate() {
  const client = useApolloClient();

  return useRelayMutation<
    SelectCheckoutShipmentShippingRateMutation,
    SelectCheckoutShipmentShippingRateMutationVariables,
    'selectCheckoutShipmentShippingRate'
  >(SelectCheckoutShipmentShippingRateDocument, 'selectCheckoutShipmentShippingRate', {
    optimisticResponse({ input }) {
      const checkout = client.readFragment<CheckoutFragment>({
        id: input.checkoutId,
        fragmentName: 'Checkout',
        fragment: CheckoutFragmentDoc
      });

      if (checkout) {
        return {
          __typename: 'Mutation',
          selectCheckoutShipmentShippingRate: {
            __typename: 'SelectCheckoutShipmentShippingRatePayload',
            checkout: {
              ...checkout,
              checkoutShipments: {
                ...checkout.checkoutShipments,
                edges: checkout.checkoutShipments?.edges?.map((edge) =>
                  edge.node.id === input.checkoutShipmentId
                    ? {
                        ...edge,
                        node: {
                          ...edge.node,
                          selectedCheckoutShippingRateId: input.checkoutShippingRateId
                        }
                      }
                    : edge
                )
              }
            },
            errors: null
          }
        };
      }

      return {
        __typename: 'Mutation',
        selectCheckoutShipmentShippingRate: {
          __typename: 'SelectCheckoutShipmentShippingRatePayload',
          checkout: null,
          errors: null
        }
      };
    }
  });
}
