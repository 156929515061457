import { Reference } from '@apollo/client';
import { useRelayMutation } from '@/lib';
import {
  ExternalAccountFragment,
  DeleteExternalAccountMutationVariables,
  DeleteExternalAccountMutation,
  CreateExternalAccountMutationVariables,
  CreateExternalAccountMutation,
  CreateExternalAccountDocument,
  DeleteExternalAccountDocument,
  ExternalAccountFragmentDoc,
  useExternalAccountsByPaymentAccountQuery
} from '@/api';

export function useCreateExternalAccount() {
  return useRelayMutation<
    CreateExternalAccountMutation,
    CreateExternalAccountMutationVariables,
    'createExternalAccount'
  >(CreateExternalAccountDocument, 'createExternalAccount', {
    update(cache, { data }) {
      if (data?.createExternalAccount.externalAccount) {
        const newExternalAccountRef = cache.writeFragment<ExternalAccountFragment>({
          fragment: ExternalAccountFragmentDoc,
          id: data.createExternalAccount.externalAccount.id,
          data: data.createExternalAccount.externalAccount
        });

        cache.modify({
          id: data.createExternalAccount.externalAccount.paymentAccountId,
          fields: {
            externalAccounts(existingExternalAccounts = { nodes: [] }) {
              return {
                nodes: [...existingExternalAccounts.nodes, newExternalAccountRef]
              };
            }
          }
        });
      }
    }
  });
}

export function useDeleteExternalAccount() {
  return useRelayMutation<
    DeleteExternalAccountMutation,
    DeleteExternalAccountMutationVariables,
    'deleteExternalAccount'
  >(DeleteExternalAccountDocument, 'deleteExternalAccount', {
    update(cache, { data }) {
      if (data?.deleteExternalAccount.externalAccount) {
        cache.modify({
          id: data.deleteExternalAccount.externalAccount.paymentAccountId,
          fields: {
            externalAccounts(existingExternalAccounts = { nodes: [] }, { readField }) {
              return {
                nodes: existingExternalAccounts.nodes.filter((externalAccountRef: Reference) => {
                  return readField('id', externalAccountRef) !== data.deleteExternalAccount.externalAccount!.id;
                })
              };
            }
          }
        });
      }
    }
  });
}

export function useExternalAccountsByPaymentAccount(paymentAccountId: string, skip?: boolean) {
  const { data, ...rest } = useExternalAccountsByPaymentAccountQuery({
    variables: {
      paymentAccountId: paymentAccountId
    },
    skip
  });

  return { externalAccounts: data?.externalAccountsByPaymentAccount?.nodes || [], ...rest };
}
