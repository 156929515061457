import { Reference } from '@apollo/client';
import { useRelayMutation } from '@/lib';
import {
  CreateVariantMutation,
  CreateVariantMutationVariables,
  CreateVariantDocument,
  VariantFragment,
  VariantFragmentDoc,
  UpdateVariantMutation,
  UpdateVariantMutationVariables,
  UpdateVariantDocument,
  useDefaultVariantQuery
} from '@/api';

export function useCreateVariant() {
  return useRelayMutation<CreateVariantMutation, CreateVariantMutationVariables, 'createVariant'>(
    CreateVariantDocument,
    'createVariant',
    {
      update(cache, { data }) {
        if (data?.createVariant.variant) {
          const newVariantRef = cache.writeFragment<VariantFragment>({
            fragment: VariantFragmentDoc,
            fragmentName: 'Variant',
            id: data.createVariant.variant.id,
            data: data.createVariant.variant!
          });

          cache.modify({
            id: data.createVariant.variant.productId,
            fields: {
              defaultVariant(existingDefaultVariantRef: Reference) {
                if (!existingDefaultVariantRef) return newVariantRef;
                return existingDefaultVariantRef;
              },
              defaultVariantId(existingDefaultVariantId: string) {
                if (!existingDefaultVariantId) return data.createVariant.variant?.id;
                return existingDefaultVariantId;
              },
              variants(existingVariants = { nodes: [] }) {
                return {
                  ...existingVariants,
                  nodes: [...existingVariants.nodes, newVariantRef]
                };
              },
              // productStock(ref) {
              //   return {
              //     ...ref,
              //     availableQuantity:
              //       data.createVariant.variant?.inventoryItem?.inventoryItemStock?.availableQuantity ?? 0,
              //     reservedQuantity:
              //       data.createVariant.variant?.inventoryItem?.inventoryItemStock?.reservedQuantity ?? 0,
              //     stockQuantity: data.createVariant.variant?.inventoryItem?.inventoryItemStock?.stockQuantity ?? 0
              //   };
              // },
              productPrices(ref) {
                return {
                  ...ref,
                  maxPrice: data.createVariant.variant?.prices.basePrice,
                  minPrice: data.createVariant.variant?.prices.basePrice,
                  maxRetailPrice: data.createVariant.variant?.prices.retailPrice,
                  minRetailPrice: data.createVariant.variant?.prices.retailPrice,
                  currency: data.createVariant.variant?.prices.currency
                };
              }
            }
          });
        }
      }
    }
  );
}

export function useUpdateVariant() {
  return useRelayMutation<UpdateVariantMutation, UpdateVariantMutationVariables, 'updateVariant'>(
    UpdateVariantDocument,
    'updateVariant',
    {
      update(cache, { data }) {
        if (data?.updateVariant.variant) {
          cache.modify({
            id: data.updateVariant.variant.productId,
            fields: {
              productPrices(ref) {
                return {
                  ...ref,
                  maxPrice: data.updateVariant.variant!.prices.basePrice,
                  minPrice: data.updateVariant.variant!.prices.basePrice,
                  maxRetailPrice: data.updateVariant.variant!.prices.retailPrice,
                  minRetailPrice: data.updateVariant.variant!.prices.retailPrice,
                  currency: data.updateVariant.variant!.prices.currency
                };
              }
            }
          });
        }
      }
    }
  );
}

export function useDefaultVariant(productId: string, skip?: boolean) {
  const { data, ...rest } = useDefaultVariantQuery({
    variables: {
      productId
    },
    skip
  });

  return { variant: data?.product?.defaultVariant || null, ...rest };
}
