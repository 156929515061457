import validate from '@ericblade/barcode-validator';

export const JATANGO_BARCODE_LENGTH = 24;

export function validateBarcode(barcode: string) {
  if (validate(barcode).valid) return true;
  if (barcode.length !== JATANGO_BARCODE_LENGTH) return false;

  let sum = 0;
  for (const char of barcode.slice(0, barcode.length - 1)) {
    sum += parseInt(char, 10);
  }
  return sum % 10 === parseInt(barcode[barcode.length - 1]);
}
