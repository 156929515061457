import {
  // RegisterUserDocument,
  // RegisterUserMutation,
  // RegisterUserMutationVariables,
  RequestEmailVerificationDocument,
  RequestEmailVerificationMutation,
  RequestEmailVerificationMutationVariables,
  useViewerQuery,
  useUsersByEmailQuery,
  useUserByUsernameQuery,
  CaptureOrUpdateUserDemographicDocument,
  CaptureOrUpdateUserDemographicMutation,
  CaptureOrUpdateUserDemographicMutationVariables,
  UpdateUserNamesDocument,
  UpdateUserNamesMutation,
  UpdateUserNamesMutationVariables
} from '@/api';
import { useRelayMutation } from '@/lib';

export function useViewer() {
  const { data, ...rest } = useViewerQuery();
  return { viewer: data?.viewer, ...rest };
}

export function useGetUsersByEmail(email: string) {
  const { data, ...rest } = useUsersByEmailQuery({
    variables: {
      email: email
    }
  });

  return { data: data, ...rest };
}

export function useGetUserByUsername(userName: string) {
  const { data, ...rest } = useUserByUsernameQuery({
    variables: {
      userName: userName
    }
  });

  return { data: data, ...rest };
}

// export function useRegisterUser() {
//   return useRelayMutation<RegisterUserMutation, RegisterUserMutationVariables, 'registerUser'>(
//     RegisterUserDocument,
//     'registerUser'
//   );
// }

export function useRequestEmailVerification() {
  return useRelayMutation<
    RequestEmailVerificationMutation,
    RequestEmailVerificationMutationVariables,
    'requestEmailVerification'
  >(RequestEmailVerificationDocument, 'requestEmailVerification');
}

export function useCaptureOrUpdateUserDemographic() {
  return useRelayMutation<
    CaptureOrUpdateUserDemographicMutation,
    CaptureOrUpdateUserDemographicMutationVariables,
    'captureOrUpdateUserDemographic'
  >(CaptureOrUpdateUserDemographicDocument, 'captureOrUpdateUserDemographic');
}

export function useUpdateUserNames() {
  return useRelayMutation<UpdateUserNamesMutation, UpdateUserNamesMutationVariables, 'updateUserNames'>(
    UpdateUserNamesDocument,
    'updateUserNames'
  );
}
