import {
  usePaymentQuery,
  UpdatePaymentMutation,
  UpdatePaymentMutationVariables,
  UpdatePaymentDocument,
  UpdateOrderPaymentMethodMutation,
  UpdateOrderPaymentMethodMutationVariables,
  UpdateOrderPaymentMethodDocument,
  CollectCashPaymentForOrderMutation,
  CollectCashPaymentForOrderMutationVariables,
  CollectCashPaymentForOrderDocument,
  SendConfirmationEmailToCustomerPosMutation,
  SendConfirmationEmailToCustomerPosMutationVariables,
  SendConfirmationEmailToCustomerPosDocument,
  ProcessCardReaderPaymentIntentMutation,
  ProcessCardReaderPaymentIntentMutationVariables,
  ProcessCardReaderPaymentIntentDocument,
  CancelCardReaderActionMutation,
  CancelCardReaderActionMutationVariables,
  CancelCardReaderActionDocument,
} from '@/api';
import { useRelayMutation } from '@/lib';

export function usePayment(id: string) {
  const { data, ...rest } = usePaymentQuery({ variables: { id } });

  return { payment: data?.payment ?? null, ...rest };
}

export function useUpdatePayment() {
  return useRelayMutation<UpdatePaymentMutation, UpdatePaymentMutationVariables, 'updatePayment'>(
    UpdatePaymentDocument,
    'updatePayment'
  );
}

export function useUpdateOrderPaymentMethod() {
  return useRelayMutation<
    UpdateOrderPaymentMethodMutation,
    UpdateOrderPaymentMethodMutationVariables,
    'updateOrderPaymentMethod'
  >(UpdateOrderPaymentMethodDocument, 'updateOrderPaymentMethod');
}

export function useCollectCashPaymentForOrder() {
  return useRelayMutation<
    CollectCashPaymentForOrderMutation,
    CollectCashPaymentForOrderMutationVariables,
    'collectCashPaymentForOrder'
  >(CollectCashPaymentForOrderDocument, 'collectCashPaymentForOrder');
}

export function useSendConfirmationEmailToCustomerPOS() {
  return useRelayMutation<
    SendConfirmationEmailToCustomerPosMutation,
    SendConfirmationEmailToCustomerPosMutationVariables,
    'sendConfirmationEmailToCustomerPOS'
  >(SendConfirmationEmailToCustomerPosDocument,'sendConfirmationEmailToCustomerPOS')
}
  
export function useProcessCardReaderPaymentIntent() {
  return useRelayMutation<
    ProcessCardReaderPaymentIntentMutation,
    ProcessCardReaderPaymentIntentMutationVariables,
    'processCardReaderPaymentIntent'
  >(
    ProcessCardReaderPaymentIntentDocument, 'processCardReaderPaymentIntent'
  )
}

export function useCancelCardReaderAction() {
  return useRelayMutation<
    CancelCardReaderActionMutation,
    CancelCardReaderActionMutationVariables,
    'cancelCardReaderAction'
  >(
    CancelCardReaderActionDocument, 'cancelCardReaderAction'
  )
}

