import { useRelayMutation } from '@/lib';
import {
  useShopPaymentAccountsQuery,
  useShopPaymentAccountsBySlugQuery,
  CreatePaymentAccountOnboardingLinkMutation,
  CreatePaymentAccountOnboardingLinkDocument,
  CreatePaymentAccountOnboardingLinkMutationVariables,
  CreatePaymentAccountMutation,
  CreatePaymentAccountMutationVariables,
  CreatePaymentAccountDocument,
  CreateExternalAccountWithDetailsMutation,
  CreateExternalAccountWithDetailsMutationVariables,
  usePaymentAccountsByShopQuery,
  CreateExternalAccountDocument,
  CreateExternalAccountWithDetailsDocument
} from '@/api';

export function useCreatePaymentAccount() {
  return useRelayMutation<CreatePaymentAccountMutation, CreatePaymentAccountMutationVariables, 'createPaymentAccount'>(
    CreatePaymentAccountDocument,
    'createPaymentAccount'
  );
}

export function useCreatePaymentAccountWithDetails() {
  return useRelayMutation<CreateExternalAccountWithDetailsMutation, CreateExternalAccountWithDetailsMutationVariables, 'createExternalAccountWithDetails'>(
    CreateExternalAccountWithDetailsDocument,
    'createExternalAccountWithDetails'
  );
}

export function useCreatePaymentAccountOnboardingLink() {
  return useRelayMutation<
    CreatePaymentAccountOnboardingLinkMutation,
    CreatePaymentAccountOnboardingLinkMutationVariables,
    'createPaymentAccountOnboardingLink'
  >(CreatePaymentAccountOnboardingLinkDocument, 'createPaymentAccountOnboardingLink');
}

export function useShopPaymentAccounts(shopId: string, skip?: boolean) {
  const { data, ...rest } = useShopPaymentAccountsQuery({
    variables: {
      shopId
    },
    skip
  });

  return {
    paymentAccounts: data?.paymentAccountsByShop?.nodes || [],
    ...rest
  };
}

export function useShopPaymentAccountsBySlug(slug: string, skip?: boolean) {
  const { data, ...rest } = useShopPaymentAccountsBySlugQuery({
    variables: {
      slug
    },
    skip
  });

  return {
    paymentAccounts: data?.paymentAccountsByShopSlug?.nodes || [],
    ...rest
  };
}

export function usePaymentAccountsByShop(slug: string, skip?: boolean) {
  const { data, ...rest } = usePaymentAccountsByShopQuery({
    variables: {
      id: slug
    },
    skip
  });

  return {
    paymentAccounts: data || [],
    ...rest
  };
}
