import {
  AddressInput,
  ShippingProfileFragment,
  useShopShippingProfilesQuery,
  ValidateAddressDocument,
  ValidateAddressQuery,
  ValidateAddressQueryVariables
} from '@/api';
import { useApolloClient } from '@apollo/client';

export function useShopShippingProfiles(shopId: string, skip?: boolean) {
  const { data, ...rest } = useShopShippingProfilesQuery({
    variables: {
      shopId
    },
    skip
  });

  return {
    shippingProfiles:
      data?.shippingProfilesByShop?.edges?.map((edge) => edge.node) ?? ([] as ShippingProfileFragment[]),
    ...rest
  };
}

export function useValidateAddress() {
  const client = useApolloClient();

  return async (address: AddressInput) => {
    const { data } = await client.query<ValidateAddressQuery, ValidateAddressQueryVariables>({
      query: ValidateAddressDocument,
      variables: {
        address
      }
    });

    return data?.validateAddress ?? null;
  };
}
