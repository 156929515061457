import { useRouter } from 'next/router';
import { useUser } from '@auth0/nextjs-auth0';
import { FbBusinessAccountState } from '@/pages/api/auth/fb-business/auth';
import { auth } from '../../../auth0.config';

export type SocialConnection = 'Facebook';

export function useAuth() {
  const { asPath } = useRouter();
  const { user, isLoading } = useUser();

  return {
    loading: isLoading,
    isLoggedIn: Boolean(user),
    user,
    loginUrl: `/api/auth/login?returnTo=${asPath}`,
    logoutUrl: '/api/auth/logout',
    login: (returnTo?: string) => {
      window.location.assign(`/api/auth/login?returnTo=${returnTo ?? asPath}`);
    },
    loginWithEmail: (email: string, returnTo?: string) => {
      window.location.assign(`/api/auth/login/email?email=${email}&returnTo=${returnTo ?? asPath}`);
    },
    loginWithSocial: (connection: SocialConnection, returnTo?: string) => {
      window.location.assign(`/api/auth/login/${connection}?returnTo=${returnTo ?? asPath}`);
    },
    loginWithFbBusiness: (state: FbBusinessAccountState) => {
      window.location.assign(`/api/auth/fb-business/login?state=${JSON.stringify(state)}`);
    },
    signup: (email?: string | null, returnTo?: string) => {
      // window.location.assign(`/api/auth/signup?returnTo=${returnTo ?? asPath}`);
      window.location.assign(`/api/auth/signup?redirect_uri=${returnTo ?? asPath}`);
      // auth.authorize({
      //   screen_hint: 'signup',
      //   redirectUri: returnTo,
      // })
      // window.location.assign(
      //   `https://${process.env.NEXT_PUBLIC_AUTH0_DOMAIN}/authorize?client_id=${process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}&response_type=code&redirect_uri=http://localhost:3000/signup/validate-phone&scope=openid%20profile%20email&audience=${process.env.AUTH0_AUDIENCE}&screen_hint=signup`
      // );
    },
    logout: () => {
      window.location.assign('/api/auth/logout');
    }
  };
}
