import { Area, Size } from 'react-easy-crop/types';

export async function createImage(url: string): Promise<HTMLImageElement> {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });
}

export async function getFileUrl(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.addEventListener('error', (error) => reject(error));
    reader.readAsDataURL(file);
  });
}

export async function resizeImage(srcUrl: string, maxSize: Size = { width: 2000, height: 2000 }) {
  const image = await createImage(srcUrl);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;

  const ratio = Math.min(maxSize.width / image.width, maxSize.height / image.height);

  const width = ratio * image.width;
  const height = ratio * image.height;

  canvas.width = width;
  canvas.height = height;

  ctx.drawImage(image, 0, 0, width, height);

  // As a blob
  return new Promise<string>((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file!));
    }, 'image/jpeg');
  });
}

export async function getCroppedImageBlob(
  srcUrl: string,
  pixelCrop: Area,
  size: Size = { width: 512, height: 512 }
): Promise<string> {
  const image = await createImage(srcUrl);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;

  // Set the canvas size to the requested size
  canvas.width = size.width;
  canvas.height = size.height;

  // draw cropped image
  ctx.drawImage(image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, size.width, size.height);

  // As a blob
  return new Promise<string>((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file!));
    }, 'image/jpeg');
  });
}
