import { useRouter } from 'next/router';
import { useEffect } from 'react';

export function useShopOrderRedirect() {
  const { query, pathname, asPath, replace } = useRouter();
  useEffect(() => {
    replace(
      {
        pathname,
        query: {
          ...query,
          disableModalAnimation: true
        }
      },
      asPath,
      {
        shallow: true
      }
    );
  }, []);
}
