import { ShopBySlugDocument, ShopBySlugQuery, ShopBySlugQueryVariables } from '@/api';
import { ServerSideQuery } from '@/lib';

export const shopBySlugQuery: ServerSideQuery<ShopBySlugQuery> = (client, context) =>
  client.query<ShopBySlugQuery, ShopBySlugQueryVariables>({
    query: ShopBySlugDocument,
    variables: {
      slug: context.params?.shopSlug as string
    }
  });
