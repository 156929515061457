import {
  UpdateInventoryItemMutation,
  UpdateInventoryItemMutationVariables,
  UpdateInventoryItemDocument,
  useInventoryItemQuery,
  InventoryItemFragment,
  InventoryItemFragmentDoc,
  useInventoryItemsQuantityChangedSubscription,
  useProductsInventoryQuantityChangedSubscription,
  Quantity
} from '@/api';
import { useRelayMutation } from '@/lib';

export function useUpdateInventoryItem() {
  return useRelayMutation<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables, 'updateInventoryItem'>(
    UpdateInventoryItemDocument,
    'updateInventoryItem'
  );
}

export function useInventoryItemQuantity(inventoryItemId: string, skip?: boolean) {
  // Get the current quantity
  const { data: inventoryItemData, ...rest } = useInventoryItemQuery({
    variables: {
      id: inventoryItemId
    },
    skip,
    fetchPolicy: 'cache-and-network'
  });

  // Subscribe for quantity changes
  const { data: subscriptionData } = useInventoryItemsQuantitySubscription([inventoryItemId], skip);

  return {
    quantity:
      subscriptionData?.inventoryItemsQuantityChanged?.quantity ?? inventoryItemData?.inventoryItem?.quantity ?? null,
    ...rest
  };
}

export function useProductsQuantitySubscription(
  productIds: string[],
  skip: boolean = false,
  onQuantityChange?: (productId: string, previous: Quantity, next: Quantity) => void
) {
  return useProductsInventoryQuantityChangedSubscription({
    variables: {
      productIds
    },
    skip,
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
    async onSubscriptionData({ client, subscriptionData }) {
      if (subscriptionData.data?.productsInventoryQuantityChanged) {
        // Update cached product quantity
        client.cache.modify({
          id: subscriptionData.data.productsInventoryQuantityChanged.productId,
          fields: {
            quantity(previous) {
              onQuantityChange?.(
                subscriptionData.data!.productsInventoryQuantityChanged.productId,
                previous,
                subscriptionData.data!.productsInventoryQuantityChanged.quantity
              );
              return subscriptionData.data!.productsInventoryQuantityChanged.quantity;
            }
          }
        });
      }
    }
  });
}

export function useInventoryItemsQuantitySubscription(
  inventoryItemIds: string[],
  skip: boolean = false,
  onQuantityChange?: (inventoryItemId: string, previous: Quantity, next: Quantity) => void
) {
  return useInventoryItemsQuantityChangedSubscription({
    variables: {
      inventoryItemIds
    },
    skip,
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
    async onSubscriptionData({ client, subscriptionData }) {
      if (subscriptionData.data?.inventoryItemsQuantityChanged) {
        // Update cached inventory item quantity
        client.cache.modify({
          id: subscriptionData.data.inventoryItemsQuantityChanged.inventoryItemId,
          fields: {
            quantity(previous) {
              onQuantityChange?.(
                subscriptionData.data!.inventoryItemsQuantityChanged.inventoryItemId,
                previous,
                subscriptionData.data!.inventoryItemsQuantityChanged.quantity
              );
              return subscriptionData.data!.inventoryItemsQuantityChanged.quantity;
            }
          }
        });
        // Update the variant quantity
        const inventoryItemFragment = client.readFragment<InventoryItemFragment>({
          id: subscriptionData.data!.inventoryItemsQuantityChanged.inventoryItemId,
          fragment: InventoryItemFragmentDoc,
          fragmentName: 'InventoryItem'
        });
        if (inventoryItemFragment) {
          client.cache.modify({
            id: inventoryItemFragment.variantId,
            fields: {
              quantity() {
                return subscriptionData.data!.inventoryItemsQuantityChanged.quantity;
              }
            }
          });
        }
      }
    }
  });
}
