import { auth } from '../../../auth0.config';

export const handleLoginUser = async (
  email: string,
  password: string,
  redirectUri: string,
  callback: (error?: any, result?: any) => void
) => {
  auth.login(
    {
      email: email,
      password: password,
      realm: 'Username-Password-Authentication',
      redirectUri: redirectUri,
      domain: process.env.AUTH0_DOMAIN,
      clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string
    },
    (error, result) => {
      callback?.(error, result);
    }
  );
};

export const handleRegisterUser = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  callback: (error?: any, result?: any) => void
) => {
  auth.signup(
    {
      email: email,
      password: password,
      connection: 'Username-Password-Authentication',
      userMetadata: {
        name: `${firstName} ${lastName}`
      }
    },
    (error, result) => {
      callback?.(error, result);
    }
  );
};

export const sendResetPasswordLink = async (email: string) => {
  const url = `https://${process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string}/dbconnections/change_password`;
  const data = {
    client_id: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string,
    email: email,
    connection: 'Username-Password-Authentication'
  };

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };

  try {
    const response = await fetch(url, options);
    return response;
  } catch (error) {
    return error;
  }
};

// Social Connections
export const handleAuthWithGoogle = async (redirectUri: string) => {
  auth.authorize({
    connection: 'google-oauth2',
    redirectUri: redirectUri
  });
};

export const handleAuthWithFacebook = async (redirectUri: string) => {
  auth.authorize({
    connection: 'Facebook',
    redirectUri: redirectUri
  });
};

export const handleAuthWithApple = async (redirectUri: string) => {
  auth.authorize({
    connection: 'apple',
    redirectUri: redirectUri
  });
};