import {
  TaxCategoryFragment,
  useTaxCategoriesByCategoryQuery,
  useSearchTaxCategoriesQuery,
  useTaxCategoriesQuery,
  useTaxCategoryQuery
} from '@/api';
import { useCallback, useState } from 'react';
import { useDebounce } from 'react-use';

export function useTaxCategory(id: string, skip?: boolean) {
  const { data, ...rest } = useTaxCategoryQuery({
    variables: {
      id
    },
    skip
  });

  return { taxCategory: (data?.taxCategory as TaxCategoryFragment | null) ?? null, ...rest };
}

export function useTaxCategories(skip?: boolean) {
  const {
    data,
    fetchMore: fetchMoreFn,
    ...rest
  } = useTaxCategoriesQuery({
    skip
  });
  const [fetchingMoreTaxCategories, setFetchingMoreTaxCategories] = useState<boolean>(false);

  const fetchMoreTaxCategories = useCallback(async () => {
    setFetchingMoreTaxCategories(true);
    try {
      await fetchMoreFn({
        variables: {
          cursor: data?.taxCategories?.pageInfo?.endCursor
        }
      });
    } finally {
      setFetchingMoreTaxCategories(false);
    }
  }, [data, fetchMoreFn, setFetchingMoreTaxCategories]);

  return {
    taxCategories: (data?.taxCategories?.edges?.map((edge) => edge.node) ?? []) as TaxCategoryFragment[],
    hasMoreTaxCategories: data?.taxCategories?.pageInfo?.hasNextPage ?? false,
    fetchingMoreTaxCategories,
    fetchMoreTaxCategories,
    ...rest
  };
}

export function useSearchTaxCategories(query: string, skip?: boolean) {
  const {
    data,
    fetchMore: fetchMoreFn,
    ...rest
  } = useSearchTaxCategoriesQuery({
    variables: {
      query
    },
    skip
  });
  const [fetchingMoreTaxCategoryResults, setFetchingMoreTaxCategoryResults] = useState<boolean>(false);

  const fetchMoreTaxCategoryResults = useCallback(async () => {
    setFetchingMoreTaxCategoryResults(true);
    try {
      await fetchMoreFn({
        variables: {
          cursor: data?.searchTaxCategories?.pageInfo.endCursor
        }
      });
    } finally {
      setFetchingMoreTaxCategoryResults(false);
    }
  }, [data, setFetchingMoreTaxCategoryResults]);

  return {
    taxCategoryResults: (data?.searchTaxCategories?.edges?.map((edge) => edge.node) ?? []) as TaxCategoryFragment[],
    hasMoreTaxCategoryResults: data?.searchTaxCategories?.pageInfo.hasNextPage ?? false,
    fetchingMoreTaxCategoryResults,
    fetchMoreTaxCategoryResults,
    ...rest
  };
}

type UseFilterTaxCategoriesOptions = {
  skip?: boolean;
  debounceDuration?: number;
};

export function useFilterTaxCategories(options?: UseFilterTaxCategoriesOptions) {
  const debounceDuration = options?.debounceDuration ?? 300;

  const [localQuery, setLocalQuery] = useState<string>('');
  const currentQuery = localQuery;
  const [searchQuery, setSearchQuery] = useState<string>(currentQuery);

  const {
    loading: loadingTaxCategories,
    taxCategories: baseTaxCategories,
    hasMoreTaxCategories: hasMoreBaseTaxCategories,
    fetchingMoreTaxCategories: fetchingMoreBaseTaxCategories,
    fetchMoreTaxCategories: fetchMoreBaseTaxCategories
  } = useTaxCategories(options?.skip);

  const {
    loading: loadingProductResults,
    taxCategoryResults,
    hasMoreTaxCategoryResults,
    fetchingMoreTaxCategoryResults,
    fetchMoreTaxCategoryResults
  } = useSearchTaxCategories(currentQuery, options?.skip);

  const search = useCallback((searchQuery: string) => {
    setLocalQuery(searchQuery);
  }, []);

  useDebounce(
    () => {
      if (searchQuery !== currentQuery) search(searchQuery);
    },
    debounceDuration,
    [searchQuery]
  );

  let taxCategories = baseTaxCategories;
  let hasMoreTaxCategories = hasMoreBaseTaxCategories;
  let fetchingMoreTaxCategories = fetchingMoreBaseTaxCategories;
  let fetchMoreTaxCategories = fetchMoreBaseTaxCategories;

  if (Boolean(currentQuery) && !loadingProductResults) {
    taxCategories = taxCategoryResults;
    hasMoreTaxCategories = hasMoreTaxCategoryResults;
    fetchingMoreTaxCategories = fetchingMoreTaxCategoryResults;
    fetchMoreTaxCategories = fetchMoreTaxCategoryResults;
  }

  return {
    loading: loadingTaxCategories,
    searching: loadingProductResults,
    taxCategories,
    hasMoreTaxCategories,
    fetchingMoreTaxCategories,
    fetchMoreTaxCategories,
    searchQuery,
    setSearchQuery,
    search
  };
}

export function useTaxCategoriesByCategory(categoryId: string, skip?: boolean) {
  const { data, ...rest } = useTaxCategoriesByCategoryQuery({
    variables: {
      categoryId
    },
    skip
  });

  return {
    taxCategories: (data?.taxCategoriesByCategory?.edges?.map((edge) => edge.node) as TaxCategoryFragment[]) ?? [],
    ...rest
  };
}
