import { ServerSideQuery } from '@/lib';
import {
  toGidString,
  UpdateCheckoutDocument,
  UpdateCheckoutMutation,
  UpdateCheckoutMutationVariables,
  ViewerCheckoutsByShopSlugDocument,
  ViewerCheckoutsByShopSlugQuery,
  ViewerCheckoutsByShopSlugQueryVariables
} from '@/api';

export const checkoutUpdateMutation: ServerSideQuery = async (client, context) => {
  if (!context.query.skipUpdate) {
    await client.mutate<UpdateCheckoutMutation, UpdateCheckoutMutationVariables>({
      mutation: UpdateCheckoutDocument,
      variables: {
        input: {
          checkoutId: toGidString('Checkout', context.params?.checkoutId as string),
          customerPaymentMethodId: context.query?.paymentMethodId
            ? toGidString('CustomerCard', context.query?.paymentMethodId as string)
            : null
        }
      }
    });
  }

  return null;
};

export const viewerCheckoutsByShopSlugQuery: ServerSideQuery = (client, context) =>
  client.query<ViewerCheckoutsByShopSlugQuery, ViewerCheckoutsByShopSlugQueryVariables>({
    query: ViewerCheckoutsByShopSlugDocument,
    variables: {
      shopSlug: context.params?.shopSlug as string
    }
  });
