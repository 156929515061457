import { useRelayMutation } from '@/lib';
import {
  UpdateLocationInventoryMutation,
  UpdateLocationInventoryMutationVariables,
  UpdateLocationInventoryDocument
} from '@/api';

export function useUpdateLocationInventory() {
  return useRelayMutation<
    UpdateLocationInventoryMutation,
    UpdateLocationInventoryMutationVariables,
    'updateLocationInventory'
  >(UpdateLocationInventoryDocument, 'updateLocationInventory');
}
