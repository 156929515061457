import { ServerSideQuery } from '@/lib';
import {
  ViewerCartItemsByShopSlugDocument,
  ViewerCartItemsByShopSlugQuery,
  ViewerCartItemsByShopSlugQueryVariables
} from '@/api';

export const viewerCartItemsByShopSlugQuery: ServerSideQuery = (client, context) =>
  client.query<ViewerCartItemsByShopSlugQuery, ViewerCartItemsByShopSlugQueryVariables>({
    query: ViewerCartItemsByShopSlugDocument,
    variables: {
      shopSlug: context.params?.shopSlug as string
    }
  });
